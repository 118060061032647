import { VmComponent } from '../../common-scripts/vmComponents'

const ursPostMessage: VmComponent = {
  name: 'urs-post-message',
  selector: '.urs-post-message',
  actions: {
    show: 'show',
    hide: 'hide',
    gticket: 'gticket',//第一个接口获取iframe发来的ticket
    getCookie:'getCookie',//第三个接口获取cookie
  },
}

export default ursPostMessage
