/**
 *
 *
 * @author fuyg
 * @date  2020-09-28
 */

import { VmComponent } from '../../common-scripts/vmComponents'

const ursLoginModal: VmComponent = {
  name: 'urs-login-modal',
  selector: '.urs-login-modal',
  actions: {
    show: 'show',
    hide: 'hide',
  },
}

export default ursLoginModal
