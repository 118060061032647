/**urs及三方登录成功回调 */
import logger from './logger'
import { createComponentBroadcast } from './componentBroadcast'
import ursLoginComponent from '../common/urs-login-component/ursLoginComponent'
import ursComponentSteps from '../common/urs-component-steps/ursComponentSteps'
import ursPostMessage from '../common/urs-post-message/ursPostMessage'
import { requestMethod } from '../common-scripts/request'
import { isSafari } from './commonMethod'

function showRequireInfo(): void {
  const { actions } = ursLoginComponent
  const loginBroadcast = createComponentBroadcast(ursLoginComponent)
  loginBroadcast.trigger(actions.hide)
  const actionsStep = ursComponentSteps.actions
  const stepBroadcastStep = createComponentBroadcast(ursComponentSteps)
  stepBroadcastStep.trigger(actionsStep.show)
}
//填写名称和渠道信息方法
function showFillInfo(): void {
  showRequireInfo()
}
//填写名称/渠道/验证手机方法
function showFillInfoPhone(): void {
  showRequireInfo()
  const actionsMessage = ursPostMessage.actions
  const messageBroadcast = createComponentBroadcast(ursPostMessage)
  messageBroadcast.trigger(actionsMessage.show)
  messageBroadcast.trigger(actionsMessage.gticket)
}
//登录成功跳转方法
function loginSuccMethods(data: any): void {
  const { code, phone, register, redirectUrl } = data
  const currentUrl = window.location.pathname
  let isLoginPage = false
  if (currentUrl.indexOf('login.s') !== -1) {
    isLoginPage = true
  } else {
    isLoginPage = false
  }
  if (code == 200 && register) {
    if (redirectUrl == 'console/#/' || !redirectUrl) {
      requestMethod('/consoleApi/ABTest/checkUser')
        .then((res) => {
          console.log('grayCheck', res)
          const { data } = res
          if (data) {
            if (isLoginPage) {
              window.location.href = 'console/#/'
            } else {
              if (isSafari()) {
                // window.location.href = 'console/#/'
                window.location.reload()
              } else {
                // window.open('console/#/')
                window.location.reload()
              }
            }
          } else {
            if (isLoginPage) {
              window.location.href = 'index.s'
            } else {
              if (isSafari()) {
                window.location.href = 'index.s'
              } else {
                window.open('index.s')
              }
            }
          }
        })
        .catch(() => {
          window.open('index.s')
        })
    } else {
      //直接跳转控制台
      window.location.href = `${redirectUrl}`
    }
  } else if (code == 200 && !register && !phone) {
    //需要验证手机号
    logger.log('验证手机号')
    showFillInfoPhone()
  } else if (code == 200 && !register && phone) {
    //只完善信息
    showFillInfo()
  }
}

export default loginSuccMethods
