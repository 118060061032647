import { VmComponent } from '../../common-scripts/vmComponents'

const ursComponentSteps: VmComponent = {
  name: 'urs-component-steps',
  selector: '.urs-component-steps',
  actions: {
    show: 'show',
    hide: 'hide',
    ticket:'ticket'//获取服务端发来的ticket
  },
}

export default ursComponentSteps
