import env from './env'

const origin = env.isDev ? 'https://ai.youdao.com' : window.location.origin
async function requestMethod (
  url: string,
  methods?: string,
  data?: any,
  contentTypes?: string,
  dataTypes?: string,
): Promise<any> {
  return await new Promise(function (reslove, reject) {
    $.ajax({
      url: `${origin}${url}`,
      type: methods ? methods : 'get',
      contentType: contentTypes
        ? contentTypes
        : 'application/x-www-form-urlencoded',
      dataType: dataTypes ? dataTypes : 'json',
      data,
      success: function (datas) {
        reslove(datas)
      },
      error: function (datas) {
        reject(datas)
      },
    })
  })
}

export { requestMethod }
