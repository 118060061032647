/**
 *

 */
/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any */

import logger from './logger'
import { VmComponent } from './vmComponents'


function buildEventName(name: string, action: string): string {
  return `broadcast:${name}:${action}`
}

function createComponentBroadcast(component: VmComponent) {
  const $win = $(window)
  const componentName = component.name

  const broadcast = {
    on(action: string, listener: any) {
      const eventName = buildEventName(componentName, action)
      $win.on(eventName, listener)
      return broadcast
    },
    one(action: string, listener: any) {
      const eventName = buildEventName(componentName, action)
      $win.one(eventName, listener)
      return broadcast
    },
    off(action: string, listener: any) {
      const eventName = buildEventName(componentName, action)
      $win.off(eventName, listener)
      return broadcast
    },
    trigger(action: string, data?: any) {
      const eventName = buildEventName(componentName, action)
      $win.trigger(eventName, data)
      logger.log('broadcast trigger: ', eventName, data)
      return broadcast
    },
  }

  return broadcast
}

export { createComponentBroadcast }
