function inputChangeStyle(selector: string): void {
  const inputValue = $(selector).val()
  if (inputValue) {
    $(selector).css('font-weight', 550)
  } else {
    $(selector).css('font-weight', 'normal')
  }
}

//判断浏览器为Safari
function isSafari(): boolean {
  return (
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  )
}

export { inputChangeStyle, isSafari }
