import { VmComponent } from '../../common-scripts/vmComponents'

const ursLoginComponent: VmComponent = {
  name: 'urs-login-component',
  selector: '.urs-login-component',
  actions: {
    show: 'show',
    hide: 'hide',
  },
}

export default ursLoginComponent
