/**
 *
 *
 * @author fuyg
 * @date  2020-09-28
 */
import { createComponentBroadcast } from '../../common-scripts/componentBroadcast'
import ursLoginModal from '../urs-login-modal/ursLoginModal'
import { requestMethod } from '../../common-scripts/request'
import loginSuccMethods from '../../common-scripts/ursLoginSuccMethod'
import logger from '@/common-scripts/logger'

$(function() {
  const { actions } = ursLoginModal
  const modalBroadcast = createComponentBroadcast(ursLoginModal)
  $('.login-urs').click(function() {
    const data = {
      autoLogin: true,
    }
    requestMethod('/j_spring_security_check', 'POST', data)
      .then((res) => {
        const { code } = res
        if (code == 200) {
          if (res.register) {
            loginSuccMethods(res)
          } else {
            modalBroadcast.trigger(actions.show)
          }
        }
      })
      .catch((err) => {
        modalBroadcast.trigger(actions.show)
        logger.log(err)
      })
    modalBroadcast.trigger(actions.show)
  })
})
