/**
 * 简单版本的console
 *
 * @author fuyg
 * @date  2020-09-28
 */

import env from './env'

const logger = {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...params: any[]): void {
    if (env.isDev) {
      console.log.call(console, ...params)
    }
  },

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...params: any[]): void {
    if (env.isDev) {
      console.error.call(console, ...params)
    }
  },

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...params: any[]): void {
    if (env.isDev) {
      console.warn.call(console, ...params)
    }
  },
}

export default logger
